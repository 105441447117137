/* eslint-disable prettier/prettier */
import { useState} from 'react';
import * as React from 'react';
import TextInput from "../../Common/Form/TextInput";
import { ISelectListItem, IPurchasedService } from '../../Common/types';
import Fetcher from "../../Common/Fetch"



interface IPropsType {
    Balance: number;
    ListServices: [];
    PurchasedServices: [];
    PurchasedLotApplicationPrices: [];
    CompanyId: number;
    Amount: number;
    AccountId: number;
    ActiveServiceName: string;
    ActiveOrderId;
    ProjectsDictionary: Record<number, string>;
}
function MyBalance(props: IPropsType) {  

    const [purchasedServices, SetPurchardServices] = useState(props.PurchasedServices);
    const [purchasedLot, SetPurcharsedLot] = useState(props.PurchasedLotApplicationPrices);
    const [selectedOption, setSelectedOption] = useState(props.ListServices[0].Value)
    const [selectedProject, setSelectedProject] = useState(Object.keys(props.ProjectsDictionary)[0])
    const [lotNum, setLotNum] = useState("")

    const handleLotNumChange = (event) => {
        setLotNum(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSelectProject = (event) => {
        setSelectedProject(event.target.value)
    }

    const GetBillMakingDepositPdf = async (companyId: number, lotnum: number) => {
        // проверим для укзанного номера лота есть ли у него возможность вносить обеспечительный платеж
        Fetcher('/ProfileApi/CheckLotDeposit?lotId=' + lotnum,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.Success) {
                        const url = new URL('/Profile/GetBillMakingDepositPdf', window.location.origin);
                        url.searchParams.append('companyId', companyId);
                        url.searchParams.append('lotnum', lotnum);
                        window.open(url.toString(), '_blank');
                    }
                    else {
                        console.log(result);
                        alert(result.Error);
                    }
                }
            );
    }

    const GetBillPaymentServicePDF = async (companyId: number, serviceId: number) => {
        const url = new URL('/Profile/GetBillPaymentServicePDF', window.location.origin);
        url.searchParams.append('companyId', companyId);
        url.searchParams.append('serviceId', serviceId);
        window.open(url.toString(), '_blank');
    }

    const GetPurchasedServices = async (accountId:number) => {
        const responce = await fetch("/OrderServiceApi/GetPurchasedServicesByAccountId",
            {
                method: "GET",
                headers: {
                    "accountId": accountId
                }
            })
        const data = responce.json();
        return data;
    }

    const CreateLotApplicationPrice = async (companyId: number,lotId:number) => {
        const responce = await fetch("/LotApplicationPriceApi/CreateLotApplicationPrice",
            {
                method: "POST",
                headers: {
                    "companyId": companyId,
                    "lotId": lotId

                }
            })
    }

    const GetLotApplicationPricesByCompanyId = async (companyId: number) => {
        const responce = await fetch("/LotApplicationPriceApi/GetLotApplicationPricesByCompanyId",
            {
                method: "GET",
                headers: {
                    "companyId": companyId.toString(),
                    "Content-Type": "application/json"
                }
            })
        const data = await responce.json()
        console.log("Fetched lot application prices:", data)
        return data
    }

    const GetServicePrice = async (id: number) => {
        const responce = await fetch("/ServiceApi/GetServiceById",
            {
            method: "GET",
            headers: {
                "id": id
            }
        })
        const data = await responce.json();
        return data.UpdatedItem.Price
    }

    const SwitchServiceProject = async (orderId: number, projectId: number) => {
        const responce = await fetch("/OrderServiceApi/ChangeOrderProject",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "orderId": orderId.toString(),
                    "projectId": projectId.toString()
                }
            })
        return responce
    }

    const AddOrder = async () => {
        const priceService = await GetServicePrice(selectedOption)
        const responce = await fetch("/OrderServiceApi/CreateOrder",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            body: JSON.stringify(
            {
                "orderServiceModel": {
                    "price": priceService,
                    "accountId": props.AccountId
                },
                "idServices": [selectedOption]
            })
        })
    }

    const RedirectToTradeLot = async (lotId) => {
        window.location.href = `/TradeLot/View?id=${lotId}`;
    }

    const handleAddPurchasedServices = async () => {
        await AddOrder();
        const purchasedServices = await GetPurchasedServices(props.AccountId)
        SetPurchardServices(purchasedServices.UpdatedItem)
        await GetBillPaymentServicePDF(props.CompanyId, selectedOption)
    }

    const handleMakingDeposit = async () => {
        await CreateLotApplicationPrice(props.CompanyId, lotNum)
        const purchasedLotApplicationPrices = await GetLotApplicationPricesByCompanyId(props.CompanyId)
        SetPurcharsedLot(purchasedLotApplicationPrices.UpdatedItem)
        await GetBillMakingDepositPdf(props.CompanyId, lotNum)
    }

    const handleSwitchServiceProject = async () => {
        const responce = await SwitchServiceProject(props.ActiveOrderId, Number(selectedProject))
        props.ActiveOrderId = 0
        props.ActiveServiceName = "Отсутствует"
        const purchasedServices = await GetPurchasedServices(props.AccountId)
        SetPurchardServices(purchasedServices.UpdatedItem)
    }

    return (
        <>
            { console.log(props.ActiveOrderId)}
            <p className="font-weight-500 roboto-font pt-2 fw-bold">Активный тарифный план: {props.ActiveServiceName}</p>
            <form>
                <p className="font-weight-500 roboto-font pt-2 fw-bold">Внести обеспечительный платёж</p>
                <div className="row">
                    <div className="col-8">
                        <TextInput onChange={handleLotNumChange} required={true} placeholder="№ лота" name="lotNum" />
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={handleMakingDeposit} className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2 mb-3">
                            Внести задаток
                        </button>
                    </div>
                </div>
                <p className="font-weight-500 roboto-font pt-2 fw-bold">Внесённые обеспечительные платежи:</p>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                    <table>
                        {purchasedLot.map((lot) => (
                            <tr onClick={() => RedirectToTradeLot(lot.LotId)} key={lot.LotId} style={{ color: "#1c2c9c" }}
                                className="font-weight-500 roboto-font pt-2 fw-bold col gutter-col-10 cursor-pointer"
                                onMouseEnter={(e) => e.currentTarget.style.textDecoration = "underline"}
                                onMouseLeave={(e) => e.currentTarget.style.textDecoration = "none"}                            >
                                <td style={{ paddingRight: "15px" }}>Лот №{lot.LotId}</td>
                                <td style={{ paddingRight: "15px" }}>{lot.OrderStatus}</td>
                                <td style={{ paddingRight: "15px" }}>{lot.Price} руб.</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </form>
            <form>
                <p className="font-weight-500 roboto-font pt-2 fw-bold">Оплатить услугу</p>
                <div className="row">
                    <div className="col-8 d-flex align-items-center">
                        <select className="form-control" name="serviceId" onChange={handleSelectChange}>{
                            props.ListServices.map((x) =>
                                <option key={x.Value} value={x.Value}>{x.Text}</option>)
                                
                        }</select>
                        
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <button onClick={handleAddPurchasedServices} type="button" className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2">Сформировать счет</button>
                    </div>
                </div>
                <p className="font-weight-500 roboto-font pt-2 fw-bold">Купленные услуги:</p>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                <table>
                    {purchasedServices.map((service) => (
                        <tr key={service.Name} style={{ color: "#1c2c9c" }} className="font-weight-500 roboto-font pt-2 fw-bold col gutter-col-10">
                            <td style={{ paddingRight: "15px" }}>{service.Name}</td>
                            <td style={{ paddingRight: "15px" }}>{service.OrderStatus}</td>
                            <td style={{ paddingRight: "15px" }}>{service.Price} руб.</td>
                        </tr>
                    ))}
                    </table>
                </div>
            </form>
            <br/>
            <form>
                <p className="font-weight-500 roboto-font pt-2 fw-bold">Перенести активную услугу на другой проект:</p>
                <div className="row">
                    <div className="col-8 d-flex align-items-center">
                        <select className="form-control" name="projectId" onChange={handleSelectProject}>{
                            Object.keys(props.ProjectsDictionary).map((key) =>
                                <option key={key} value={key}>{props.ProjectsDictionary[key]}</option>)

                        }</select>

                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <button onClick={handleSwitchServiceProject}  type="button" className="btn btn-primary btn-font-14 btn-lg btn-lg-px-2 btn-block px-2">Выполнить перенос</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default MyBalance