import React, { useState, useEffect } from "react";
import { DataTable }           from 'primereact/datatable';
import { Column }              from 'primereact/column';
import { Dropdown }            from 'primereact/dropdown';
import { Tag }                 from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import   Fetcher               from "../../Common/Fetch";
import { PaymentLotStatus, PaymentLotStatusMap, PaymentLotStatusMap2 }    from "../../Common/types";

/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту PaymentsLotsTable для его работы. 
*/
interface IPropsType {
}

/**
   @interface Payment - интерфейс предоставляющий сведения о лоте, продавце, покупателе и статусе оплаты 
*/
interface Payment {
    //  id - идентификатор записи в БД (таблица PaymentLot)
    Id:             string;

    // имя продавца лота
    SellerName:     string;

    // название лота
    LotName:        string;

    // идентификатор лота
    LotId:          string;

    // стоимость лота
    Price:          number;

    // категория к которой пренадлежит лот
    Category:       string;

    // количество к покупке
    Quantity:       number;

    // имя покупателя лота
    ByuerName:      string;

    // идентификатор покупателя
    ByuerId:        number;

    // статус оплаты
    PaymentStatus: PaymentLotStatus;

    // дата создание платежа
    DateCreated:   any;
}

/**
   @function PaymentsLotsTable - функциональный компонет представляющий из себя таблицу по платежам за лоты
*/
function PaymentsLotsTable(props: IPropsType) {

    // свойство которое хранит в себе список платежей по лотам и следит за изменениями в этих записях. Реагируя на изменения перерисовкой частей комопннета.
    const [rowsData, setRowsData] = useState<Payment[]>([]);

    // сообщение об ошибки
    const [error, setError] = useState({ isError: false, message: '' });

    // блокирует таблицу на момент загрузки данных
    const [loading, setLoading] = useState(true);

    // статусы платежей для выпадающего dropdown компонента
    const [statuses] = useState([
        { key: PaymentLotStatus.NoPaid, value: PaymentLotStatusMap.get(PaymentLotStatus.NoPaid) },
        { key: PaymentLotStatus.PaidComission, value: PaymentLotStatusMap.get(PaymentLotStatus.PaidComission) },
        { key: PaymentLotStatus.Paid, value: PaymentLotStatusMap.get(PaymentLotStatus.Paid) },
        { key: PaymentLotStatus.Cancel, value: PaymentLotStatusMap.get(PaymentLotStatus.Cancel) }
    ]);

    // хук который взывается после загрузки компонента и выполняет подгрузку данных с сервера
    useEffect(() => {
        init();       
    }, []);

    // метод инициализации компонента. Запрашивает данные для таблицы с сервера.
    const init = () => {
        if (rowsData.length == 0) {
            Fetcher('/Admin/TradeLotApi/GetPaymentsLots/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setRowsData(data.Data);
                    setLoading(false);
                });
        }
    }

    // метод возвращающий шаблон для вывода данных в колонке Статус
    const StatusText = (item) => {
        console.log(item);
        return <Tag value={PaymentLotStatusMap.get(item.PaymentStatus)} severity={getSeverity(item.PaymentStatus)}></Tag> ;
    };

    // данный метод возвращает цвет плашки по статусу платежа
    const getSeverity = (status) => {

        switch (status) {
            case PaymentLotStatus.Paid:
                return 'success';

            case PaymentLotStatus.NoPaid:
                return 'info';

            case PaymentLotStatus.PaidComission:
                return 'warning';

            case PaymentLotStatus.Cancel:
                return 'danger';
        }
    };

    // данный метод вызывается при попытки сохранить отредактированный данные в таблице
    const onRowEditComplete = (e) => {
        
        let _products = [...rowsData];
        let { newData, index } = e;
        newData.PaymentStatus = statuses.find(x => x.value === newData.PaymentStatus).key;

        // сохраним на сервер, пока сохраняем только поле статус

        Fetcher('/Admin/TradeLotApi/ChangeStatus?' + 'id=' + newData.Id + '&' + 'status=' + newData.PaymentStatus,
            {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (!data.Success)
                    setError({ isError: true, message: 'Не удалось обновить статус платежа!' });
                else {

                    _products[index] = newData;

                    setRowsData(_products);
                    console.log(newData);
                }
            });
    };

    // данный метод вызывается при клике на кнопку редактировать данные и возвращает новое представление
    // dropdown для колонки Статус
    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                optionLabel="value"
                onChange={(e) => { options.editorCallback(e.value) }}
                placeholder="Выберите статус"
            />
        );
    };

    // данный метод позволяет опредлять параметры по которым может быть разрешено/запрещено редактирование записи в таблице
    const allowEdit = (rowData) => {
        return true;
    };

    // данный метод показывает сообщение об ошибке над таблице
    const showErrorMessage = (message) => {
        setError({ isError: true, message: message });
    }

    // возвращаем таблицу DataTable из PrimeReact библиотеки с нашими данными
    return (
        <React.Fragment>
            {/*  сообщение уведомляющее об ошибке при совершении дейтсвия */}
            <div className="alert alert-danger" id={'alert_error'} style={{ marginBottom: '0px', display: !error.isError ? "none" : 'block' }} role="alert">
                <span>{error.message}</span>
            </div>
            <DataTable  value={rowsData}
                        dataKey="Id"
                        paginator rows={5}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        loading={loading}
                        editMode="row"
                        onRowEditComplete={onRowEditComplete}
                        tableStyle={{ minWidth: '10rem' }}>

                <Column field="SellerName"        header="Продавец">                </Column>
                <Column field="LotName"           header="Название лота">           </Column>
                <Column field="LotId"             header="Идентификатор лота">      </Column>
                <Column field="Category"          header="Категория">               </Column>
                <Column field="Quantity"          header="Количество к покупке">    </Column>
                <Column field="ByuerName"         header="Имя покупателя">          </Column>
                <Column field="ByuerId"           header="Идентификатор покупателя"></Column>

                <Column field="PaymentStatus"     header="Статус оплаты"
                                                  body={StatusText} style={{ width: '20%' }}
                                                  editor={(options) => statusEditor(options)}>           
                </Column>

                <Column field="DateCreated"       header="Дата создания платежа" sortable></Column>

                {/* дополнительная колонка в которой поазывается карандашик для редактирования данных в строке таблицы */}
                <Column rowEditor={allowEdit}
                        headerStyle={{ width: '10%', minWidth: '8rem' }} 
                        bodyStyle={{ textAlign: 'center' }}>
                </Column>
            </DataTable>
        </React.Fragment>
    );
}

export default PaymentsLotsTable;