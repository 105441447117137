import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";

interface IPropsType {
    label: string,
    name: string,
    value?: string | number,
    required: boolean,
    removeValidationMsg?: boolean,
    type?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    inputClass?: string,
    tooltipText?: string,
    placeholder?: string,
    children?: React.ReactNode,
    additionalLabelClass?: string,
    additionalInputClass?: string,
    wrapInsideContainer?: boolean
    errorMessage?: string,
    allowOnlyNumbers?: boolean,
    allowOnlyDecimals?: boolean,
    defaultValue?: string,
    disabled?: boolean
}


function textInput(props: IPropsType) {
    const stdLabelClass = 'field-label-16-roboto ';
    const stdInputClass = (!props.removeValidationMsg && props.errorMessage ? " is-invalid" : "") + " form-control app-form-control";

    const inputComponent = (<input type={props.type ? props.type : "text"}
        {...
        (props.value && { value: props.value })
        }
        name={props.name}
        /*value={props.value}*/
        defaultValue={props.defaultValue}
        
        onChange={(e) => {
            const numRegex = /^[0-9\b]+$/;
            const decimalRegex = /^\d*\.?\d*$/;

            if (!props.allowOnlyNumbers && !props.allowOnlyDecimals) {
                props.onChange(e);
            }


            else {
                if (props.allowOnlyNumbers && (e.target.value === '' || numRegex.test(e.target.value))) {
                    props.onChange(e);
                }

                else if (props.allowOnlyDecimals && (e.target.value === '' || decimalRegex.test(e.target.value))) {
                    props.onChange(e);
                }
                else {
                    e.target.value = null;
                    props.onChange(e);
                }

            }

        }}
        onBlur={props.onBlur} required={props.required} className={(props.inputClass ? props.inputClass : stdInputClass) + (props.additionalInputClass ? ' ' + props.additionalInputClass : '')} placeholder={props.placeholder}
    />);

    const errorComponent = (<React.Fragment>{!props.removeValidationMsg && props.errorMessage && <p className={"invalid-feedback mb-0 pt-2" + (props.wrapInsideContainer ? " always-block" : "")}>{props.errorMessage}</p>}</React.Fragment>);

    if (props.wrapInsideContainer) {
        return (<React.Fragment>
            {props.label && <h6 className={props.additionalLabelClass ? stdLabelClass + props.additionalLabelClass : stdLabelClass}>{props.label}</h6>}

            <div className="input-custom mb-4" style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <div className="row gutter-row-10 align-items-center">
                    <div className="col gutter-col-10">
                        {inputComponent}
                    </div>
                    {props.children}
                </div>
                {errorComponent}
            </div></React.Fragment>);
    }

    return (<React.Fragment>
        {props.label && <h6 className={props.additionalLabelClass ? stdLabelClass + props.additionalLabelClass : stdLabelClass}>{props.label}</h6>}
        <div className="input-custom mb-4" style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            {inputComponent}
            {errorComponent}
        </div>
        
    </React.Fragment>);


}

export default textInput;